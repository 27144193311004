import React, { forwardRef } from "react";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    /** This is used to show a label above the input. */
    label?: string;
    /** This is used when there is an error to display the error styles for the input. */
    hasError?: boolean;
    /** This is used when there is an error to display a message to the user. */
    errorMessage?: string;
    /** This is used to disable the hover styles when using the input on certain background colours e.g. grey hover on grey background. */
    disableHoverStyles?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        label,
        hasError = false,
        errorMessage,
        className = "",
        required,
        ...restProps
    } = props;

    return (
        <>
            {label && props.type !== "hidden" && (
                <label
                    htmlFor={restProps?.name || restProps?.id}
                    className={`mb-[10px] text-sm block font-bold`}
                >
                    {label}{" "}
                    {required ? (
                        <span className="text-brand-danger">*</span>
                    ) : (
                        <span className="italic text-[12px] font-normal text-brand-dark-grey">
                            {" "}
                            &#8212; optional
                        </span>
                    )}
                </label>
            )}

            <input
                className={`h-[45px] w-full text-[14px] px-[15px] pt-[13px] pb-[14px] rounded-[30px] border placeholder-opacity-50 placeholder-brand-black transition-color duration-200 disabled:cursor-not-allowed focus:border-brand-primary outline-none ${
                    hasError ? "border-brand-danger" : "border-brand-grey"
                } ${errorMessage ? "mb-[5px]" : ""} ${className}`}
                ref={ref}
                {...restProps}
            />

            {errorMessage && (
                <p className={`text-xs mt-0 mb-[15px] text-brand-danger block`}>
                    {errorMessage}
                </p>
            )}
        </>
    );
});

export default Input;
