import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";
import ProductRestrictions from "packages/product-restrictions";
import TestHarnesses from "packages/test-harnesses";
import UserForm from "packages/peracto-user/UserForm";

import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";

import blocks from "./blocks";
import { menu } from "./menu";

import compact from "lodash/compact";
import startCase from "lodash/startCase";

import * as buttonVariants from "./theme/buttonVariants";
import { colors, fonts } from "./theme/tailwind";

import {
  Input,
  Textarea,
  Select,
  Radio,
  Checkbox,
  CheckboxGroup,
  File,
} from "./Inputs";

const config = new Config({
  api: process.env.REACT_APP_API,
  algolia: {
    applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
  },
  modules: [
    PeractoUser({ UserForm }),
    PeractoCommon(),
    PeractoCatalogue(),
    PeractoLocations(),
    PeractoPromotions(),
    PeractoContent(),
    ProductRestrictions(),
    TestHarnesses(),
  ],
  editorConfig: {
    isTailwind: true,
    theme: EditorTheme,
    colorOptions: compact(
      Object.entries(colors).map(([label, value]) => {
        return {
          label: startCase(label.replaceAll("brand-", "")),
          value,
        };
      })
    ),
    fonts,
    locales: ["en_GB"],
    blocks,
    buttonVariants,
    inputTypes: {
      Input,
      Textarea,
      Select,
      Radio,
      Checkbox,
      CheckboxGroup,
      File,
    },
    stylesheets: [
      "https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap",
    ],
    globalStyles: GlobalStyles,
  },
features: {
  dashboard: {
      tables: true,
  },
  shippingServices: {
      deliveryDays: true,
      conditions: true,
  },
  products: {
      exportProductData: true,
      productVariants: true,
  },
  productRestrictions: {
    exportData: true,
  },
  user: {
      userGroups: true,
      userSettings: true,
      companySettings: true,
      manageVAT: true,
  },
  content: {
      allowDisableContentElementAreas: true,
  }
},
  menu,
});

const App = () => (
  <ThemeProvider theme={AdminTheme}>
    <Peracto config={config} />
  </ThemeProvider>
);

export default App;
