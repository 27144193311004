import {
    Accordion,
    Carousel,
    Container,
} from "@peracto/peracto-blocks-tailwind";

import ProductsCarousel from './ProductsCarousel'

export default {
    plugins: {
        content: [Carousel, Accordion, ProductsCarousel],
        layout: [Container],
    },
};
