import React from "react";
import { Field } from "formik";

import { Checkbox as TWCheckbox } from "../components/Checkbox";

const Checkbox = (props) => (
    <div className="mb-[15px]">
        <Field name={props.name}>
            {({ field, form }) => (
                <TWCheckbox
                    aria-label={props.label}
                    checked={field.value}
                    onChange={(e) =>
                        form.setFieldValue(field.name, e.target.checked)
                    }
                    isDisabled={form.isSubmitting || props.disabled}
                    {...props}
                />
            )}
        </Field>

        {props?.help?.length > 0 && (
            <p className="text-brand-dark-grey text-[12px] mb-[15px] md:mb-[30px]">
                {props.help}
            </p>
        )}
    </div>
);

export default Checkbox;
