import React from "react";
import { Field, FieldArray } from "formik";

import { Checkbox as TWCheckbox } from "../components/Checkbox";

const CheckboxGroup = (props) => {
    return (
        <>
            {props?.label && (
                <label
                    htmlFor={props?.name || props?.id}
                    className={`mb-[10px] text-sm block font-bold`}
                >
                    {props.label}{" "}
                    {props.required ? (
                        <span className="text-brand-danger">*</span>
                    ) : (
                        <span className="italic text-[12px] font-normal text-brand-dark-grey">
                            {" "}
                            &#8212; optional
                        </span>
                    )}
                </label>
            )}
            {props?.options?.length > 0 && (
                <Field name={props.name}>
                    {({ field, form }) => (
                        <FieldArray name={field.name}>
                            {({ push, remove }) => (
                                <div className="flex flex-col">
                                    {props.options.map((option, idx) => {
                                        return (
                                            <div className="mb-[5px]">
                                                <TWCheckbox
                                                    id={option.value}
                                                    key={`${field.name}-${idx}`}
                                                    label={option.label}
                                                    aria-label={option.label}
                                                    checked={field.value.includes(
                                                        option.value
                                                    )}
                                                    data-value={option.value}
                                                    disabled={
                                                        form.isSubmitting ||
                                                        props.disabled
                                                    }
                                                    onChange={(e) => {
                                                        const target =
                                                            e.currentTarget;

                                                        if (target.checked) {
                                                            push(option.value);
                                                        } else {
                                                            const optionIdx =
                                                                field.value.indexOf(
                                                                    option.value
                                                                );
                                                            remove(optionIdx);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </FieldArray>
                    )}
                </Field>
            )}
            {props?.help?.length > 0 && (
                <p className="text-brand-dark-grey text-[12px] mb-[15px] md:mb-[30px]">
                    {props.help}
                </p>
            )}
        </>
    );
};

export default CheckboxGroup;
