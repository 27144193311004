import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";

export const CopyButton = ({ content }) => {
    const [copied, setCopied] = useState(false);
    return (
        <div className="flex flex-row items-center justify-end">
            <span
                className={`text-success mr-3 transition-opacity ${
                    copied ? "opacity-1" : "opacity-0"
                }`}
            >
                <FontAwesomeIcon icon={faCheckCircle} /> Copied
            </span>
            <button
                className="btn btn-sm btn-outline-primary"
                onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(content);
                    setCopied(true);
                }}
            >
                <FontAwesomeIcon icon={faCopy} /> Copy
            </button>
        </div>
    );
};
