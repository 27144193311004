const sharedClasses =
    "flex items-center justify-center text-[16px] leading-[1] rounded-[23px] uppercase w-auto px-[30px] pt-[13px] pb-[12px] font-semibold transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-25";

export const primary = {
    solidClasses: `${sharedClasses} bg-brand-primary text-brand-white hover:bg-brand-primary-darken hover:text-brand-white active:bg-brand-primary-darken active:shadow-[0_0_0_4px_rgba(92,146,25,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-primary border-2 border-brand-primary hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary-darken active:shadow-[0_0_0_4px_rgba(92,146,25,0.4)]`,
};

export const secondary = {
    solidClasses: `${sharedClasses} bg-brand-black text-brand-white hover:bg-brand-black-darken hover:text-brand-white active:bg-brand-black-darken active:shadow-[0_0_0_4px_rgba(33,49,66,0.4)]`,
    outlineClasses: `${sharedClasses} bg-none text-brand-black border-2 border-brand-black hover:bg-brand-black hover:text-brand-white active:bg-brand-black-darken active:shadow-[0_0_0_4px_rgba(33,49,66,0.4)]`,
};
