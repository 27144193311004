/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react'
import { useConfig } from '@peracto/peracto-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxFull } from '@fortawesome/pro-regular-svg-icons/faBoxFull'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom'
import isEmpty from 'lodash/isEmpty'
import EmblaCarousel from '../../utils/EmblaCarousel'
import { ProductCard } from '../../../components/ProductCard/ProductCard'

// Attributes that don't start with 'attributes.*'
const UNPREFIXED_ATTRIBUTES = ['sku']

let Icon = () => (
    <div className="flex items-center justify-center w-full py-10">
        <FontAwesomeIcon icon={faBoxFull} size="6x" />
    </div>
)

const Hits = (props) => {
    const { hits, isEditMode, progressIndicatorStyle, accentColour, arrowColour } = props

    const inStorefront = !window?.location?.pathname.includes('/content/edit/')

    return hits.length > 0 ? (
        <EmblaCarousel
            accentColour={accentColour}
            arrowColour={arrowColour}
            progressIndicatorStyle={progressIndicatorStyle}
        >
            {hits?.map((hit, index) => {
                // Product information
                const title = hit?.attributes?.product_name
                const slug = hit?.slug
                const sku = hit?.sku
                const description = hit?.attributes?.description

                const formattedPrices = {
                    price: {
                        exc: 0,
                        inc: 0,
                    },
                }

                // Resources
                const firstImage = hit?.resources?.find((r) => r.type === 'image')?.location

                return (
                    <div key={hit?.slug || `hit_${index}`} className="flex-[0_0_235px]">
                        <ProductCard
                            key={hit.objectID}
                            product={hit}
                            title={title}
                            slug={slug}
                            sku={sku}
                            description={description}
                            image={firstImage}
                            prices={formattedPrices}
                            className="h-full"
                        />
                    </div>
                )
            })}
        </EmblaCarousel>
    ) : isEditMode || !inStorefront ? (
        <Icon />
    ) : null
}
const CustomHits = connectHits(Hits)

const ProductsCarouselContent = ({
    numberOfProducts = 4,
    attributes = [],
    attributeOperator = 'and',
    cardStyle = 'square',
    progressIndicatorStyle = 'dots',
    imageFit = 'cover',
    showPrices = false,
    incVAT = false,
    fontFamily = '',
    fontWeight = 'normal',
    fontColour = '#000',
    accentColour = '#000',
    arrowColour = '#FFFFFF',
    fallbackImage = '',
    isEditMode,
    useSiloImages = false,
}) => {
    const config = useConfig()
    const algolia = config.get('algolia') || {}

    const { applicationId, searchApiKey, indexName } = algolia

    const searchClient = algoliasearch(applicationId, searchApiKey)

    const [facetFilters, setFacetFilters] = useState()

    useEffect(() => {
        if (attributes?.length > 0) {
            const categories = attributes?.reduce((acc, { attribute, value }) => {
                const attributeKey = UNPREFIXED_ATTRIBUTES.includes(attribute)
                    ? attribute
                    : `attributes.${attribute}`
                if (value.length > 0) acc.push(`${attributeKey}:${value}`)

                return acc
            }, [])

            setFacetFilters(categories)
        }
    }, [attributes])

    return useMemo(() => {
        if (isEmpty(algolia)) {
            console.error(
                'No Algolia configuration defined. Please refer to the Peracto documentation.',
            )

            return null
        }

        return (
            <InstantSearch searchClient={searchClient} indexName={indexName}>
                <Configure
                    hitsPerPage={numberOfProducts}
                    facetFilters={
                        facetFilters
                            ? attributeOperator === 'and'
                                ? facetFilters
                                : [facetFilters]
                            : null
                    }
                />

                <CustomHits
                    isEditMode={isEditMode}
                    cardStyle={cardStyle}
                    progressIndicatorStyle={progressIndicatorStyle}
                    imageFit={imageFit}
                    showPrices={showPrices}
                    incVAT={incVAT}
                    useSiloImages={useSiloImages}
                    fontFamily={fontFamily}
                    fontWeight={fontWeight}
                    fontColour={fontColour}
                    accentColour={accentColour}
                    arrowColour={arrowColour}
                    fallbackImage={fallbackImage}
                />
            </InstantSearch>
        )
    }, [
        facetFilters,
        numberOfProducts,
        cardStyle,
        progressIndicatorStyle,
        imageFit,
        showPrices,
        incVAT,
        useSiloImages,
        attributeOperator,
        fontFamily,
        fontWeight,
        fontColour,
        accentColour,
        arrowColour,
        isEditMode,
        fallbackImage,
    ])
}

const ProductsCarouselRenderer = ({ state, ...props }) => {
    return <ProductsCarouselContent {...state} {...props} />
}

export default ProductsCarouselRenderer
