import React, { useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
    Form,
    Group,
    Input,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import { isJson } from "../util";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    userEmail: yup.string().required("User Email is required"),
});

export const GetCustomerForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState("");

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);
        setResponseData("");

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/get-customer`,
                {
                    userEmail: formData.userEmail,
                },
                {
                    headers,
                }
            );

            setResponseData(data?.response);
        } catch (e) {
            const errorMessage = isJson(e?.response?.data?.["hydra:description"]) ? JSON.parse(e?.response?.data?.["hydra:description"])?.error?.message : e?.response?.data?.["hydra:description"] || e?.message

            toast.error(errorMessage);
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Get Customer | Test Harnesses | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Get Customer" />
                <Form
                    autoComplete="on"
                    values={{
                        userEmails: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="request-payload"
                        id="request-payload"
                        name="Request Payload"
                    >
                        <Input name="userEmail" label="User Email" testId="user-email" required help="The User email e.g. test@test.com" />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>


                    {loading && (
                        <div className="flex items-center justify-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {responseData && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    DC Get Customer Response
                                </h4>{" "}
                                <CopyButton content={JSON.stringify(responseData)} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={JSON.stringify(responseData)}
                                    language="json"
                                />
                            </div>
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

GetCustomerForm.displayName = "GetCustomerForm";
