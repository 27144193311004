const { darken, lighten } = require("polished");

// Used so hover colors get automatically updated
const brandPrimaryColor = "#004F9E";
const brandSecondaryColor = "#E30513";
const brandBlackColor = "#222222";
const brandWhiteColor = "#FFFFFF";

const colors = {
  // Base colors
  "brand-primary": brandPrimaryColor,
  "brand-secondary": brandSecondaryColor,
  "brand-success": "#2DB200",
  "brand-danger": "#E30613",
  "brand-pale-danger": "#FED7D7",
  "brand-white": brandWhiteColor,
  "brand-light-grey": "#F2F2F2",
  "brand-grey": "#D8D8D8",
  "brand-dark-grey": "#505050",
  "brand-black": brandBlackColor,

  // Hover colors
  "brand-primary-lighten": lighten(0.05, brandPrimaryColor),
  "brand-primary-darken": darken(0.05, brandPrimaryColor),

  "brand-secondary-lighten": lighten(0.05, brandSecondaryColor),
  "brand-secondary-darken": darken(0.05, brandSecondaryColor),

  "brand-black-lighten": lighten(0.05, brandBlackColor),
  "brand-black-darken": darken(0.05, brandBlackColor),
};

const fonts = {
  heading: ["Arial, sans-serif"],
  body: ["Arial, sans-serif"],
};

module.exports = { colors, fonts };
