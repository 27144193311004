import React from "react";
import { Route, Switch } from "react-router-dom";

import { GetPricesForm } from "./get-prices";
import { GetInvoicesForm } from "./get-invoices";
import { GetAccountBalanceForm } from "./get-account-balance";
import { GetSalesOrdersForm } from "./get-sales-orders";
import { GetProductForm } from "./get-product";
import { GetCustomerForm } from "./get-customer";
import { SendOrderForm } from "./send-order";
import { ImportPaymentForm } from "./import-payment";

export default () => {
    return {
        routes: [
            {
                path: "/test-harnesses",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}/get-prices`}
                            exact
                            component={GetPricesForm}
                        />
                        <Route
                            path={`${match.url}/get-invoices`}
                            exact
                            component={GetInvoicesForm}
                        />
                        <Route
                            path={`${match.url}/get-account-balance`}
                            exact
                            component={GetAccountBalanceForm}
                        />
                        <Route
                            path={`${match.url}/get-sales-orders`}
                            exact
                            component={GetSalesOrdersForm}
                        />
                        <Route
                            path={`${match.url}/get-product`}
                            exact
                            component={GetProductForm}
                        />
                        <Route
                            path={`${match.url}/get-customer`}
                            exact
                            component={GetCustomerForm}
                        />
                        <Route
                            path={`${match.url}/send-order`}
                            exact
                            component={SendOrderForm}
                        />
                        <Route
                            path={`${match.url}/import-payment`}
                            exact
                            component={ImportPaymentForm}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
