/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Form, Group, Input, Tags, Select, Modal, Checkbox, FormActions } from '@peracto/peracto-ui'
import { Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'

import { toast } from 'react-toastify'
import startCase from 'lodash/startCase'

import AddressForm from './AddressForm'
import * as S from './styled'

import { CREATE, DELETE, GET_LIST, useClient } from '@peracto/client'
import { useConfig } from '@peracto/peracto-config'
import { useSettings } from '@peracto/peracto-hooks'
import { roleValues } from '@peracto/peracto-user'
import { defaultSelectValue, days, months, years, roles, titles } from './util'

export const MODE_ADD = 'add'
export const MODE_EDIT = 'edit'

const UserForm = ({
    mode = MODE_EDIT,
    values,
    setFormData = () => {},
    onSaveAddress,
    testId,
    ...props
}) => {
    const [showUserDialog, setShowUserDialog] = useState(false)

    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [sendingPasswordReset, setSendingPasswordReset] = useState(false)
    const [visibleFields, setVisibleFields] = useState({})
    const [customerGroups, setCustomerGroups] = useState([])
    const [branches, setBranches] = useState([])
    const [userGroups, setUserGroups] = useState([])
    const [redirect, setRedirect] = useState()
    const { client } = useClient()

    const config = useConfig()
    const { user } = config.get('features', {})
    const defaultBranch = user?.defaultBranch ?? false

    const { values: settingsValues } = useSettings()

    const user_types = settingsValues?.user_types

    const onDelete = async () => {
        try {
            await client(DELETE, 'users', {
                id: values.user.id,
            })

            toast.success('User deleted successfully!')
            setRedirect('/users')
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    const onResetPassword = async () => {
        setSendingPasswordReset(true)
        try {
            await client(CREATE, 'users/reset-password', {
                data: {
                    email: values.user.email,
                },
            })

            setShowPasswordReset(false)
            setSendingPasswordReset(false)
            toast.success('Password reset email sent!')
        } catch (e) {
            console.error(e)
            setSendingPasswordReset(false)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    const fetchCustomerGroups = async (inputValue = '') => {
        const { data } = await client(GET_LIST, 'customer-groups', {
            id: 'customer-groups',
            label: inputValue,
        })

        const values = data.map(val => ({
            label: val.name,
            value: val.id,
        }))

        setCustomerGroups(values)

        return values
    }

    const fetchBranches = async (inputValue = '') => {
        const { data } = await client(GET_LIST, 'locations?locationTypes.type=branch', {
            id: 'locations',
            label: inputValue,
        })

        const values = data.map(val => ({
            label: val.name,
            value: val.id,
        }))

        setBranches(values)

        return values
    }

    const fetchUserGroups = async (inputValue = '') => {
        const { data } = await client(GET_LIST, 'user-groups', {
            id: 'user-groups',
            label: inputValue,
        })

        const values = data.map(val => ({
            label: val.name,
            value: val.identifier,
        }))

        setUserGroups(values)

        return values
    }

    useEffect(() => {
        if (user?.userGroups) {
            fetchUserGroups()
        } else {
            fetchCustomerGroups()
        }

        defaultBranch && fetchBranches()
    }, [user])

    return (
        <>
            <div data-testid={testId}>
                {redirect && <Redirect to={redirect} />}

                {mode === MODE_EDIT && (
                    <FormActions>
                        <>
                            <a onClick={() => setShowPasswordReset(true)}>
                                <FontAwesomeIcon icon={faLock} className="mr-2" />
                                Reset Password
                            </a>

                            <a className="text-danger" onClick={() => setShowUserDialog(true)}>
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                Delete User
                            </a>
                        </>
                    </FormActions>
                )}

                <Form autoComplete="off" values={values} {...props}>
                    <Group key="customer" id="customer" name="Customer">
                        {mode === MODE_EDIT && (
                            <div className="d-flex justify-content-between align-items-start">
                                <div>
                                    {!visibleFields.customer && (
                                        <S.Address>
                                            <p className="address-name">
                                                {values.user.firstName} {values.user.lastName}
                                            </p>
                                            <p>{values.user.email}</p>
                                            <p>{values.user.telephone}</p>
                                            <p>
                                                {values.user.roles.map((val, idx) => (
                                                    <span key={`role-${idx}`}>
                                                        {idx > 0 ? ', ' : ''}
                                                        {roleValues[val]}
                                                    </span>
                                                ))}
                                            </p>
                                        </S.Address>
                                    )}
                                </div>

                                <button
                                    type="button"
                                    role="button"
                                    className="p-0 btn btn-link"
                                    onClick={() => {
                                        setVisibleFields({
                                            ...visibleFields,
                                            customer: !visibleFields.customer,
                                        })
                                    }}
                                >
                                    {visibleFields.customer ? (
                                        <>
                                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            Close
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                            Edit
                                        </>
                                    )}
                                </button>
                            </div>
                        )}

                        {(mode === MODE_ADD || visibleFields.customer) && (
                            <>
                                <Select
                                    name="user.title"
                                    label="Title"
                                    options={titles}
                                    placeholder="Select a title"
                                    testId="title"
                                    testIdItems="title__item"
                                    testIdIndex={0}
                                />
                                <Input
                                    name="user.firstName"
                                    label="First name"
                                    required
                                    autoComplete="first-name"
                                    testId="firstname"
                                />
                                <Input
                                    name="user.lastName"
                                    label="Last name"
                                    required
                                    autoComplete="last-name"
                                    testId="lastname"
                                />
                                <Input
                                    name="user.email"
                                    label="Email"
                                    required
                                    autoComplete="email"
                                    testId="email"
                                />
                                <Input name="user.telephone" label="Telephone" testId="telephone" />
                                <Input name="user.mobile" label="Mobile Number" testId="mobile" />
                                <Input
                                    name="user.businessTelephoneNumber"
                                    label="Business Telephone"
                                    testId="business-telephone"
                                />

                                {defaultBranch && branches?.length > 0 && (
                                    <Field name="user.defaultBranch">
                                        {({ field, form }) => (
                                            <div className="form-group">
                                                <label>Default Branch</label>
                                                <Select
                                                    name={field.name}
                                                    className="w-100"
                                                    isSearchable={true}
                                                    onChange={option => {
                                                        form.setFieldValue(field.name, option.value)
                                                    }}
                                                    options={branches}
                                                    placeholder="Search for Branches..."
                                                    testId="branches"
                                                    testIdItems="branches__item"
                                                    testIdIndex={1}
                                                    allowClear={true}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                )}
                                <p className="mb-1">Date of Birth</p>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <Select
                                            name="user.splitDateOfBirth.day"
                                            label="Day"
                                            defaultValue={defaultSelectValue}
                                            options={[
                                                defaultSelectValue,
                                                ...days.map(day => {
                                                    return { label: day, value: day }
                                                }),
                                            ]}
                                        />
                                    </div>

                                    <div className="col-12 col-md-3">
                                        <Select
                                            name="user.splitDateOfBirth.month"
                                            label="Month"
                                            defaultValue={defaultSelectValue}
                                            options={[defaultSelectValue, ...months]}
                                        />
                                    </div>

                                    <div className="col-12 col-md-3">
                                        <Select
                                            name="user.splitDateOfBirth.year"
                                            label="Year"
                                            defaultValue={defaultSelectValue}
                                            options={[
                                                defaultSelectValue,
                                                ...years.map(year => {
                                                    return { label: year, value: year }
                                                }),
                                            ]}
                                        />
                                    </div>

                                    <div className="col-3 d-flex">
                                        <Field name="user.splitDateOfBirth">
                                            {({ form }) => (
                                                <>
                                                    <button
                                                        className="px-0 mt-2 btn btn-link"
                                                        type="button"
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            form.setFieldValue(
                                                                'user.splitDateOfBirth.day',
                                                                ''
                                                            )
                                                            form.setFieldValue(
                                                                'user.splitDateOfBirth.month',
                                                                ''
                                                            )
                                                            form.setFieldValue(
                                                                'user.splitDateOfBirth.year',
                                                                ''
                                                            )
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            className="mr-2"
                                                        />
                                                        Clear Value
                                                    </button>
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>

                                <Select
                                    name="user.canCollectOrder"
                                    label="Can Collect"
                                    options={[
                                        { label: 'Yes', value: true },
                                        { label: 'No', value: false },
                                    ]}
                                    testId="can-collect"
                                    testIdItems="can-collect__item"
                                />

                                <Select
                                    name="user.enabled"
                                    label="Account Status"
                                    options={[
                                        { label: 'Enabled', value: true },
                                        { label: 'Disabled', value: false },
                                    ]}
                                    testId="account-status"
                                    testIdItems="account-status__item"
                                />

                                <Tags
                                    name="user.roles"
                                    label="Roles"
                                    options={roles}
                                    testId="roles"
                                    testIdItems="roles__item"
                                />
                                {user?.userGroups ? (
                                    <>
                                        {userGroups?.length > 0 && (
                                            <Field name="user.userGroup">
                                                {({ field, form }) => (
                                                    <div className="form-group">
                                                        <label>User Group</label>
                                                        <Select
                                                            name={field.name}
                                                            className="w-100"
                                                            isSearchable={true}
                                                            onChange={option => {
                                                                form.setFieldValue(
                                                                    field.name,
                                                                    option.value
                                                                )
                                                            }}
                                                            defaultValue={{
                                                                label: 'Search for User Groups...',
                                                                value: null,
                                                            }}
                                                            options={userGroups}
                                                            placeholder="Search for User Groups..."
                                                            testId="user-groups"
                                                            testIdItems="user-groups__item"
                                                            testIdIndex={1}
                                                            allowClear={true}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {customerGroups?.length > 0 && (
                                            <Field name="user.customerGroup">
                                                {({ field, form }) => (
                                                    <div className="form-group">
                                                        <label>Customer Group</label>
                                                        <Select
                                                            name={field.name}
                                                            className="w-100"
                                                            isSearchable={true}
                                                            onChange={option => {
                                                                form.setFieldValue(
                                                                    field.name,
                                                                    option.value
                                                                )
                                                            }}
                                                            options={customerGroups}
                                                            placeholder="Search for Customer Groups..."
                                                            testId="customer-groups"
                                                            testIdItems="customer-groups__item"
                                                            testIdIndex={1}
                                                            allowClear={true}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                        )}
                                    </>
                                )}
                                {user_types?.length > 0 && (
                                    <Field name="user.type">
                                        {({ field, form }) => (
                                            <div className="form-group">
                                                <label>Customer Type</label>
                                                <Select
                                                    name={field.name}
                                                    className="w-100"
                                                    isSearchable={true}
                                                    onChange={option => {
                                                        form.setFieldValue(field.name, option.value)
                                                    }}
                                                    options={user_types.map(type => {
                                                        return {
                                                            label: startCase(type),
                                                            value: type,
                                                        }
                                                    })}
                                                    placeholder="Please Select"
                                                    testId="user-types"
                                                    testIdItems="user-types__type"
                                                    testIdIndex={1}
                                                    allowClear={true}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                )}
                                <Input
                                    name="user.companyName"
                                    label="Company Name"
                                    testId="company-name"
                                />
                                {user?.companySettings && (
                                    <Input
                                        name="user.accountNumber"
                                        label="Account Number"
                                        testId="account-number"
                                    />
                                )}
                                <Select
                                    name="user.currency"
                                    label="Currency"
                                    options={[
                                        { label: 'GBP', value: 'GBP' },
                                        { label: 'Euro', value: 'EUR' },
                                    ]}
                                    placeholder="Select a currency"
                                    testId="currency"
                                    testIdItems="currency__item"
                                    testIdIndex={0}
                                />
                                {user?.manageVAT && (
                                    <Select
                                        name="user.vat"
                                        label="Include VAT"
                                        options={[
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false },
                                        ]}
                                        placeholder="Include VAT"
                                        testId="vat"
                                        testIdItems="vat__item"
                                    />
                                )}

                                <Checkbox
                                    name="user.hasCreditTerms"
                                    label="User has Credit Terms"
                                />
                            </>
                        )}
                    </Group>
                </Form>

                {mode === MODE_EDIT && (
                    <>
                        <AddressForm
                            values={values}
                            visibleFields={visibleFields}
                            setVisibleFields={setVisibleFields}
                            setFormData={setFormData}
                            titles={titles}
                            onSaveAddress={onSaveAddress}
                            {...props}
                        />

                        <Modal
                            isVisible={showUserDialog}
                            title="Delete User"
                            close={() => setShowUserDialog(false)}
                            buttons={[
                                {
                                    type: 'btn-outline-secondary',
                                    text: 'Close',
                                    action: () => setShowUserDialog(false),
                                },
                                {
                                    type: 'btn-danger',
                                    text: 'Delete User',
                                    action: () => onDelete(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="mb-4 d-block"
                            />
                            Are you sure you would like to permanently delete the account of{' '}
                            {values.user.email}? Deleted users cannot be recovered.
                        </Modal>

                        <Modal
                            isVisible={showPasswordReset}
                            title="Reset Password"
                            close={() => setShowPasswordReset(false)}
                            buttons={[
                                {
                                    type: 'btn-outline-secondary',
                                    text: 'Cancel',
                                    action: () => setShowPasswordReset(false),
                                },
                                {
                                    type: 'btn-success',
                                    text: sendingPasswordReset ? 'Sending Email...' : 'Send Email',
                                    disabled: sendingPasswordReset,
                                    action: () => onResetPassword(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="mb-4 d-block"
                            />
                            Are you sure you would like to generate a reset password email for{' '}
                            {values.user.email}?
                        </Modal>
                    </>
                )}
            </div>
        </>
    )
}

UserForm.displayName = 'UserForm'
UserForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setFormData: PropTypes.func,
    countries: PropTypes.array,
}

export default UserForm
