import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'

const EmblaCarousel = ({ accentColour, arrowColour, progressIndicatorStyle, children }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({
        breakpoints: {
            '(min-width: 768px)': {
                align: 'start',
            },
        },
        skipSnaps: false,
    })

    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState([])

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])
    const scrollTo = useCallback(index => emblaApi && emblaApi.scrollTo(index), [emblaApi])

    const onInit = useCallback(emblaApi => {
        setScrollSnaps(emblaApi.scrollSnapList())
    }, [])

    const onSelect = useCallback(emblaApi => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onInit(emblaApi)
        onSelect(emblaApi)
        emblaApi.on('reInit', onInit)
        emblaApi.on('reInit', onSelect)
        emblaApi.on('select', onSelect)
    }, [emblaApi, onInit, onSelect])

    return (
        <div className="px-[20px]">
            <div className="relative w-full overflow-hidden" ref={emblaRef}>
                <div className={`flex gap-x-[30px] mb-[30px]`}>{children}</div>
                <button
                    onClick={scrollNext}
                    style={{ backgroundColor: accentColour }}
                    className={`w-[50px] h-[50px] rounded-full cursor-pointer absolute top-1/2 right-0 -translate-y-[calc(50%+60px)] flex items-center justify-center ${
                        nextBtnDisabled ? 'hidden' : 'hidden md:block'
                    }`}
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ color: arrowColour }}
                        className="text-2xl"
                    />
                </button>
                <button
                    onClick={scrollPrev}
                    style={{ backgroundColor: accentColour }}
                    className={`w-[50px] h-[50px] rounded-full cursor-pointer absolute top-1/2 left-0 -translate-y-[calc(50%+60px)] flex items-center justify-center ${
                        prevBtnDisabled ? 'hidden' : 'hidden md:block'
                    }`}
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        style={{ color: arrowColour }}
                        className="text-2xl"
                    />
                </button>

                {/* {progressIndicatorStyle === 'bar' && (
                    <div className="flex items-center justify-center mt-[30px]">
                        <div className="w-full h-[5px] border-b border-[#D8D8D8]">
                            <div
                                style={{
                                    width: `${(selectedIndex + 1) * (100 / scrollSnaps.length)}%`,
                                    backgroundColor: accentColour,
                                }}
                                className="h-full transition-all duration-300 ease-in-out"
                            />
                        </div>
                    </div>
                )} */}

                {progressIndicatorStyle === 'dots' && (
                    <div className="flex items-center justify-center gap-x-[15px] mt-[30px] md:mt-[60px]">
                        {scrollSnaps.map((_, index) => (
                            <div
                                key={index}
                                onClick={() => scrollTo(index)}
                                style={{
                                    backgroundColor:
                                        index === selectedIndex ? accentColour : '#D8D8D8',
                                }}
                                className="w-[15px] h-[15px] rounded-full cursor-pointer"
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default EmblaCarousel
