import React from 'react'

import { defaultSettings } from './defaults/settings'
import Component from './Component'
import ProductsCarouselForm from './Form/ProductsCarouselForm'

const createPlugin = settings => {
    const mergedSettings = { ...defaultSettings, ...settings }

    return {
        Component: props => <Component {...props} {...mergedSettings} />,
        FormComponent: ProductsCarouselForm,

        name: 'peracto/content/productsCarousel',
        version: '1.0.0',
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        createInitialState: () => ({
            numberOfProducts: 4,
            cardStyle: 'square',
            progressIndicatorStyle: 'dots',
            imageFit: 'cover',
            fontFamily: '',
            fontWeight: 'normal',
            fontColour: '#000',
            accentColour: '#000',
            arrowColour: '#FFFFFF',
            fallbackImage: '',
            useSiloImages: false,
        }),
    }
}

export default createPlugin
