import React from 'react'
import { twMerge } from 'tailwind-merge'

export const ProductCard = (props) => {
    const {
        product,
        title,
        sku,
        slug,
        image,
        prices,
        onAddToBasket,
        className = '',
        ...restProps
    } = props

    return (
        <div
            className={twMerge('flex flex-col justify-between bg-brand-white rounded-[5px] p-[15px] border border-brand-grey', className)}
            data-testid="product-card"
            {...restProps}
        >
            <>
                <a
                    href={`/products/${slug}`}
                    className="group !no-underline"
                    data-testid="product-card__link"
                >
                    <img
                        src={image || '/images/placeholder.jpg'}
                        alt={title || ''}
                        width={300}
                        height={300}
                        className="object-cover mb-[15px] aspect-square rounded-[5px]"
                        data-testid="product-card__image"
                    />

                    <p
                        className="text-sm font-bold text-brand-dark-grey lg:text-lg mb-[5px] lg:mb-[10px] group-hover:underline"
                        data-testid="product-card__title"
                    >
                        {title}
                    </p>
                </a>

                <div>
                    <p
                        className="text-brand-secondary font-bold lg:text-2xl text-base flex gap-x-[10px] flex-wrap items-end"
                        data-testid="product-card__price"
                    >
                        Sign in for prices
                    </p>
                    <p className="text-sm mb-[15px]">
                        <span className="block mt-[5px]">
                            or{' '}
                            <a
                                href="/register"
                                className="underline text-brand-primary"
                                data-testid="product-card__apply-link"
                            >
                                apply
                            </a>{' '}
                            for a trade account online
                        </span>
                    </p>
                </div>
            </>
        </div>
    )
}
