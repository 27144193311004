import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'
import { faCloudDownloadAlt } from '@fortawesome/pro-regular-svg-icons/faCloudDownloadAlt'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import download from 'downloadjs'
import { Heading, Group, Form, Input } from '@peracto/peracto-ui'
import { useConfig } from '@peracto/peracto-config'


const ProductRestrictionsExport = () => {
    const config = useConfig()
    const API_URL = config.get('api')

    const handleSubmit = async (type, values = {}) => {
        const filteredValues = {}

        for (const [field, value] of Object.entries(values)) {
            if (!!value && field==='accountNumber') {
                const accounts = value.split(',').map(account => account.trim());
                accounts.forEach((account, index) => {
                    filteredValues[`accountNumber[${index}]`] = account;
                });
            }
        }

        let filename = `${type}.csv?${new URLSearchParams(filteredValues).toString()}`

        const { data } = await axios.get(`${API_URL}/export/${filename}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })

        const filenameDetails = Object.values(filteredValues).join('_')

        download(data, `${type}_export${filenameDetails ? `_${filenameDetails}` : ''}.csv`)
    }

    return (
        <>
            <Helmet>
                <title>Export Product Restrictions Data | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Export Product Restrictions Data">
                    <div className="ml-2 d-flex align-items-center">
                        <Link
                            className="btn btn-outline-primary"
                            to="/product-restrictions"
                            data-testid="export-product-restrictions-data"
                        >
                            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                            Back to Product Restrictions
                        </Link>
                    </div>
                </Heading>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Group key="productRestrictions" id="productRestrictions" name="Product Restrictions">
                            <Form
                                values={{
                                    accountNumber: '',
                                }}
                                showActionPanel={false}
                                onSubmit={values => handleSubmit('product_restriction', values)}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <Input
                                            name="accountNumber"
                                            type="text"
                                            min="1"
                                            label="Account Numbers"
                                            help="Use to filter product restrictions by single or multiple account numbers. They have to be separated by comma. Example: TUR02 or TUR02, ABA01"
                                        />
                                    </div>

                                    <div className="col-12">

                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-product-restrictions"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Product Restrictions
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ProductRestrictionsExport
