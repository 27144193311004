import React, { useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import * as yup from "yup";
import { toast } from "react-toastify";
import {
    Form,
    Group,
    Input,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import { isJson } from "../util";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    customerNumber: yup.string().required("Customer Number is required"),
    skus: yup.string().required("At least one SKU is required"),
});

export const GetPricesForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);
        setRequestData("");
        setResponseData("");

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        const formattedSkus = formData.skus.split(",").map((sku) => sku.trim());

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/get-prices`,
                {
                    customerNumber: formData.customerNumber,
                    skus: formattedSkus,
                },
                {
                    headers,
                }
            );

            setRequestData(data?.request);
            setResponseData(data?.response);
        } catch (e) {
            const errorMessage = isJson(e?.response?.data?.["hydra:description"]) ? JSON.parse(e?.response?.data?.["hydra:description"])?.error?.message : e?.response?.data?.["hydra:description"] || e?.message
            
            toast.error(errorMessage);
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Get Prices | Test Harnesses | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Get Prices" />
                <Form
                    autoComplete="on"
                    values={{
                        customerNumber: "",
                        skus: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="request-payload"
                        id="request-payload"
                        name="Request Payload"
                    >
                        <Input name="customerNumber" label="Customer Number" testId="customer-number" required help="The customer number e.g. TUR02" />
                        <Input name="skus" label="SKUs" testId="skus" required help="A comma separated list of SKUs e.g. 571010001, 571032710" />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>


                    {loading && (
                        <div className="flex items-center justify-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {requestData && !loading && (
                        (() => {
                            const combinedRequestData = {
                                ...requestData,
                                items: JSON.parse(requestData.items)
                            };

                            return (
                                <Group
                                    key="request-details"
                                    id="request-details"
                                    name="Request Details"
                                >
                                    <div className="flex items-center justify-between mt-2 mb-3">
                                        <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                            Get Prices Request
                                        </h4>{" "}
                                        <CopyButton content={JSON.stringify(combinedRequestData)} />
                                    </div>
                                    <div className="border rounded-[4px] p-2">
                                        <CodeFormatter
                                            code={JSON.stringify(combinedRequestData)}
                                            language="json"
                                        />
                                    </div>
                                </Group>
                            );
                        })()
                    )}

                    {responseData && !loading && (
                        (() => {
                            const combinedResponseData = {
                                ...responseData,
                                value: JSON.parse(responseData.value)
                            };

                            return (
                                <Group
                                    key="response-details"
                                    id="response-details"
                                    name="Response Details"
                                >
                                    <div className="flex items-center justify-between mt-2 mb-3">
                                        <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                            Get Prices Response
                                        </h4>{" "}
                                        <CopyButton content={JSON.stringify(combinedResponseData)} />
                                    </div>
                                    <div className="border rounded-[4px] p-2">
                                        <CodeFormatter
                                            code={JSON.stringify(combinedResponseData)}
                                            language="json"
                                        />
                                    </div>
                                </Group>
                            );
                        })()
                    )}
                </Form>
            </div>
        </>
    );
};

GetPricesForm.displayName = "GetPricesForm";
