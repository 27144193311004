import React from "react";

import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";
import "highlight.js/styles/stackoverflow-light.css";
import xmlFormatter from "xml-formatter";

hljs.registerLanguage("xml", xml);
hljs.registerLanguage("json", json);

export const CodeFormatter = ({
    code,
    language = "xml",
}: {
    code: string;
    language?: "xml" | "json";
}) => {
    let formattedCode = code;

    if (language === "xml") {
        formattedCode = xmlFormatter(code);
    } else if (language === "json") {
        formattedCode = JSON.stringify(JSON.parse(code), null, 2);
    }

    const highlightedCode = hljs.highlight(formattedCode, {
        language,
    }).value;

    return (
        <pre>
            <code
                dangerouslySetInnerHTML={{
                    __html: highlightedCode,
                }}
            ></code>
        </pre>
    );
};
