import createPlugin from './createPlugin'

import ProductsCarouselRenderer from './Renderer/ProductsCarouselRenderer'

const productsCarouselPlugin = settings =>
    createPlugin({
        Renderer: ProductsCarouselRenderer,
        ...settings,
    })

const productsCarousel = productsCarouselPlugin()
export default productsCarousel

export { productsCarouselPlugin, ProductsCarouselRenderer }
